<template>
  <v-container>
    <v-row>
      <div class="container">
        <h2><span>Reports</span></h2>
        <label for="User:">{{ this.currentUser }}</label>
      </div>
    </v-row>

    <v-row style="align-items: flex-start">
      <div class="col-md-3">
        <div class="tab-wrap">
          <v-bottom-navigation :value="activeBtn" class="tab-wrap-menu">
            <ul class="mouse-pointer">
              <li @click="change_step(1)" style="display: block">
                <b>Open Uploads/Tasks by Deal</b>
              </li>
              <li @click="change_step(2)" style="display: block">
                <b>Open Uploads/Tasks by Broker</b>
              </li>
              <li @click="change_step(3)" style="display: block">
                <b>Last Activity by User</b>
              </li>
              <li @click="change_step(4)" style="display: block">
                <b>User Access by Deal</b>
              </li>
              <li @click="change_step(5)" style="display: block">
                <b>User Activity Log</b>
              </li>
              <li @click="change_step(6)" style="display: block">
                <b>Offers Disposition</b>
              </li>
              <li @click="change_step(7)" style="display: block">
                <b>Offer History</b>
              </li>
              <li @click="change_step(8)" style="display: block">
                Additional Report 4
              </li>
              <li @click="change_step(0)" style="display: block">
                Additional Report 5
              </li>
              <li @click="change_step(10)" style="display: block">
                Additional Report 6
              </li>
              <li @click="change_step(11)" style="display: block">
                Additional Report 7
              </li>
              <li @click="change_step(12)" style="display: block">
                Additional Report 8
              </li>
              <li @click="change_step(13)" style="display: block">
                Additional Report
              </li>
              <li @click="change_step(14)" style="display: block">
                Additional Report
              </li>
            </ul>
          </v-bottom-navigation>
        </div>
      </div>

      <div class="col-md-6" style="background-color: #f0f2f4">
        <v-container>
          <!--Open Uploads/Tasks by Deal-->
          <div class="customer-detail-step" v-if="current_step == 1">
            <h2>Open Uploads/Tasks by Deal</h2>
            <v-row>
              <v-col cols="5" class="col-md-5">
                <label>Order Deal: </label>
                <template id="Sort">
                  <div>
                    <b-form-group
                      label="Sort Order: "
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="ReportParams.sort_desc"
                        :options="options"
                        :aria-describedby="ariaDescribedby"
                        name="radio-inline"
                      ></b-form-radio-group>
                    </b-form-group>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
              <v-col class="col-md-5" col="md-5">
                <template id="Deal">
                  <div>
                    <label><b>Deal</b></label>
                    <b-form-select
                      v-model="ReportParams.param1"
                      :options="list_deal"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param1 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col
                v-if="(curUserType == 2) == false"
                class="col-md-5"
                col="md-5"
              >
                <template id="Broker">
                  <div>
                    <label><b>Broker</b></label>
                    <b-form-select
                      v-model="ReportParams.param2"
                      :options="list_broker"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param2 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col class="col-md-5" col="md-5">
                <b-button @click="resetFilterItems" variant="outline-primary">
                  Reset Filters >
                </b-button>
              </v-col>
            </v-row>
          </div>

          <!--Open Uploads/Tasks by Broker-->
          <div class="customer-detail-step" v-if="current_step == 2">
            <h2>Open Uploads/Tasks by Broker</h2>
            <v-row>
              <v-col cols="5" class="col-md-5">
                <label>Order Broker: </label>
                <template id="Sort">
                  <div>
                    <b-form-group
                      label="Sort Order: "
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="ReportParams.sort_desc"
                        :options="options"
                        :aria-describedby="ariaDescribedby"
                        name="radio-inline"
                      ></b-form-radio-group>
                    </b-form-group>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
              <v-col
                v-if="(curUserType == 2) == false"
                class="col-md-5"
                col="md-5"
              >
                <template id="Broker">
                  <div>
                    <label><b>Broker</b></label>
                    <b-form-select
                      v-model="ReportParams.param2"
                      :options="list_broker"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param2 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col class="col-md-5" col="md-5">
                <template id="Deal">
                  <div>
                    <label><b>Deal</b></label>
                    <b-form-select
                      v-model="ReportParams.param1"
                      :options="list_deal"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param1 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>

              <v-col class="col-md-5" col="md-5">
                <b-button @click="resetFilterItems" variant="outline-primary">
                  Reset Filters >
                </b-button>
              </v-col>
            </v-row>
          </div>

          <!--Last Activity by User -->
          <div class="customer-detail-step" v-if="current_step == 3">
            <h2>Last Activity by User</h2>
            <v-row>
              <v-col v-if="isAdmin == 'true'" cols="5" class="col-md-5">
                <label>Order User: </label>
                <template id="Sort">
                  <div>
                    <b-form-group
                      label="Sort Order: "
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="ReportParams.sort_desc"
                        :options="options"
                        :aria-describedby="ariaDescribedby"
                        name="radio-inline"
                      ></b-form-radio-group>
                    </b-form-group>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="isAdmin == 'true'" class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
              <v-col v-if="isAdmin == 'true'" class="col-md-5" col="md-5">
                <template id="User">
                  <div>
                    <label><b>User</b></label>
                    <b-form-select
                      v-model="ReportParams.param2"
                      :options="list_user"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param2 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col v-if="isAdmin == 'true'" class="col-md-5" col="md-5">
                <template id="Deal">
                  <div>
                    <label><b>Deal</b></label>
                    <b-form-select
                      v-model="ReportParams.param1"
                      :options="list_deal"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param1 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>

              <v-col v-if="isAdmin == 'true'" class="col-md-5" col="md-5">
                <b-button @click="resetFilterItems" variant="outline-primary">
                  Reset Filters >
                </b-button>
              </v-col>
            </v-row>
          </div>

          <!--User Access by Deal-->
          <div class="customer-detail-step" v-if="current_step == 4">
            <h2>User Access by Deal</h2>
            <v-row>
              <v-col cols="5" class="col-md-5">
                <label>Order User: </label>
                <template id="Sort">
                  <div>
                    <b-form-group
                      label="Sort Order: "
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="ReportParams.sort_desc"
                        :options="options"
                        :aria-describedby="ariaDescribedby"
                        name="radio-inline"
                      ></b-form-radio-group>
                    </b-form-group>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
              <v-col v-if="isAdmin == 'true'" class="col-md-5" col="md-5">
                <template id="User">
                  <div>
                    <label><b>User</b></label>
                    <b-form-select
                      v-model="ReportParams.param2"
                      :options="list_user"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param2 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col class="col-md-5" col="md-5">
                <template id="Deal">
                  <div>
                    <label><b>Deal</b></label>
                    <b-form-select
                      v-model="ReportParams.param1"
                      :options="list_deal"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param1 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>

              <v-col v-if="isAdmin == 'true'" class="col-md-5" col="md-5">
                <b-button @click="resetFilterItems" variant="outline-primary">
                  Reset Filters >
                </b-button>
              </v-col>
            </v-row>
          </div>

          <!--User Activity Log-->
          <div class="customer-detail-step" v-if="current_step == 5">
            <h2>User Activity Log</h2>
            <v-row>
              <v-col cols="5" class="col-md-5">
                <label>Order User: </label>
                <template id="Sort">
                  <div>
                    <b-form-group
                      label="Sort Order: "
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="ReportParams.sort_desc"
                        :options="options"
                        :aria-describedby="ariaDescribedby"
                        name="radio-inline"
                      ></b-form-radio-group>
                    </b-form-group>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
              <v-col v-if="isAdmin == 'true'" class="col-md-5" col="md-5">
                <template id="User">
                  <div>
                    <label><b>User</b></label>
                    <b-form-select
                      v-model="ReportParams.param2"
                      :options="list_user"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param2 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col class="col-md-5" col="md-5">
                <template id="Deal">
                  <div>
                    <label><b>Deal</b></label>
                    <b-form-select
                      v-model="ReportParams.param1"
                      :options="list_deal"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param1 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>

              <v-col v-if="isAdmin == 'true'" class="col-md-5" col="md-5">
                <b-button @click="resetFilterItems" variant="outline-primary">
                  Reset Filters >
                </b-button>
              </v-col>
            </v-row>
          </div>

          <!--Offers Disposition-->
          <div class="customer-detail-step" v-if="current_step == 6">
            <h2>Offers Disposition</h2>
            <v-row> </v-row>

            <v-row>
              <v-col class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="isAdmin !== 'true'" class="col-md-5" col="md-5">
                <template id="Buyer">
                  <div>
                    <label><b>Buyer</b></label>
                    <b-form-select
                      v-model="ReportParams.param2"
                      :options="list_buyer"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param2 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col class="col-md-5" col="md-5">
                <template id="Disposition">
                  <div>
                    <label><b>Deal</b></label>
                    <b-form-select
                      v-model="ReportParams.param1"
                      :options="list_deal"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param1 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
          </div>

          <!--Offer History-->
          <div class="customer-detail-step" v-if="current_step == 7">
            <h2>Offer History</h2>
            <v-row> </v-row>

            <v-row>
              <v-col class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="isAdmin !== 'true'" class="col-md-5" col="md-5">
                <template id="Buyer">
                  <div>
                    <label><b>Buyer</b></label>
                    <b-form-select
                      v-model="ReportParams.param2"
                      :options="list_buyer"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param2 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col class="col-md-5" col="md-5">
                <template id="Disposition">
                  <div>
                    <label><b>Deal</b></label>
                    <b-form-select
                      v-model="ReportParams.param1"
                      :options="list_deal"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param1 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
          </div>

          <!--Additional Report 4-->
          <div class="customer-detail-step" v-if="current_step == 8">
            <h2>Additional Report 4</h2>
            <v-row>
              <v-col cols="5" class="col-md-5">
                <label>Order Bid Date: </label>
                <template id="Sort">
                  <div>
                    <b-form-group
                      label="Sort Order: "
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="ReportParams.sort_desc"
                        :options="options"
                        :aria-describedby="ariaDescribedby"
                        name="radio-inline"
                      ></b-form-radio-group>
                    </b-form-group>
                  </div>
                </template>

                <!-- <v-radio-group label="Sort Order: " v-model="ReportParams.sort_desc" row>
                                    <v-radio name="sort" label="  Asc" :value=false></v-radio>
                                    <v-radio name="sort" label="  Desc" :value=true></v-radio>                
                                </v-radio-group> -->
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
              <v-col class="col-md-5" col="md-5">
                <template id="StartDate">
                  <div>
                    <label><b>Start Date</b></label>
                    <b-form-datepicker
                      id="start-datepicker"
                      class="mb-2"
                      placeholder="Select Start Date"
                      v-model="ReportParams.param1"
                    ></b-form-datepicker>
                  </div>
                </template>
              </v-col>
              <v-col class="col-md-5" col="md-5">
                <template id="Rep">
                  <div>
                    <label><b>Rep</b></label>
                    <b-form-select
                      v-model="ReportParams.param3"
                      :options="list_rep"
                      class="mb-3"
                      value-field="id"
                      text-field="text"
                      disabled-field="notEnabled"
                    ></b-form-select>
                    <div class="mt-3">
                      Selected: <strong>{{ ReportParams.param3 }}</strong>
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-5" col="md-5">
                <template id="EndDate">
                  <div>
                    <label><b>End Date</b></label>
                    <b-form-datepicker
                      id="end-datepicker"
                      class="mb-2"
                      :min="ReportParams.param1"
                      placeholder="Select End Date"
                      v-model="ReportParams.param2"
                    ></b-form-datepicker>
                  </div>
                </template>
              </v-col>
            </v-row>
          </div>

          <!--Additional Report 5-->
          <div class="customer-detail-step" v-if="current_step == 9">
            <h2>Additional Report 5</h2>
            <v-row> </v-row>

            <v-row>
              <v-col class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
              <v-col class="col-md-7" col="md-5">
                <template id="StartDate">
                  <div>
                    <label><b>Start Date</b></label>
                    <b-form-datepicker
                      id="start-datepicker"
                      class="mb-2"
                      placeholder="Select Start Date"
                      v-model="ReportParams.param1"
                    ></b-form-datepicker>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-7" col="md-5">
                <template id="EndDate">
                  <div>
                    <label><b>End Date</b></label>
                    <b-form-datepicker
                      id="end-datepicker"
                      class="mb-2"
                      :min="ReportParams.param1"
                      placeholder="Select End Date"
                      v-model="ReportParams.param2"
                    ></b-form-datepicker>
                  </div>
                </template>
              </v-col>
            </v-row>
          </div>

          <!--Additional Report 6-->
          <div class="customer-detail-step" v-if="current_step == 10">
            <h2>Additional Report 6</h2>
            <v-row>
              <v-col cols="5" class="col-md-5">
                <label>Order Closed Date: </label>
                <template id="Sort">
                  <div>
                    <b-form-group
                      label="Sort Order: "
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="ReportParams.sort_desc"
                        :options="options"
                        :aria-describedby="ariaDescribedby"
                        name="radio-inline"
                      ></b-form-radio-group>
                    </b-form-group>
                  </div>
                </template>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
              <v-col class="col-md-7" col="md-5">
                <template id="StartDate">
                  <div>
                    <label><b>Start Date</b></label>
                    <b-form-datepicker
                      id="start-datepicker"
                      class="mb-2"
                      placeholder="Select Start Date"
                      v-model="ReportParams.param1"
                    ></b-form-datepicker>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-7" col="md-5">
                <template id="EndDate">
                  <div>
                    <label><b>End Date</b></label>
                    <b-form-datepicker
                      id="end-datepicker"
                      class="mb-2"
                      :min="ReportParams.param1"
                      placeholder="Select End Date"
                      v-model="ReportParams.param2"
                    ></b-form-datepicker>
                  </div>
                </template>
              </v-col>
            </v-row>
          </div>

          <!--Additional Report 7-->
          <div class="customer-detail-step" v-if="current_step == 11">
            <h2>Additional Report 7</h2>
            <v-row>
              <v-col cols="5" class="col-md-5">
                <label>Order Closed Date: </label>
                <template id="Sort">
                  <div>
                    <b-form-group
                      label="Sort Order: "
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="ReportParams.sort_desc"
                        :options="options"
                        :aria-describedby="ariaDescribedby"
                        name="radio-inline"
                      ></b-form-radio-group>
                    </b-form-group>
                  </div>
                </template>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
              <v-col class="col-md-7" col="md-5">
                <template id="StartDate">
                  <div>
                    <label><b>Start Date</b></label>
                    <b-form-datepicker
                      id="start-datepicker"
                      class="mb-2"
                      placeholder="Select Start Date"
                      v-model="ReportParams.param1"
                    ></b-form-datepicker>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-7" col="md-5">
                <template id="EndDate">
                  <div>
                    <label><b>End Date</b></label>
                    <b-form-datepicker
                      id="end-datepicker"
                      class="mb-2"
                      :min="ReportParams.param1"
                      placeholder="Select End Date"
                      v-model="ReportParams.param2"
                    ></b-form-datepicker>
                  </div>
                </template>
              </v-col>
            </v-row>
          </div>

          <!--Additional Report 8-->
          <div class="customer-detail-step" v-if="current_step == 12">
            <h2>Additional Report 8</h2>
            <v-row>
              <v-col cols="5" class="col-md-5">
                <label>Order Closed Date: </label>
                <template id="Sort">
                  <div>
                    <b-form-group
                      label="Sort Order: "
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="ReportParams.sort_desc"
                        :options="options"
                        :aria-describedby="ariaDescribedby"
                        name="radio-inline"
                      ></b-form-radio-group>
                    </b-form-group>
                  </div>
                </template>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-md-12" col="md-12">
                <label>Reports Filters:</label>
              </v-col>
              <v-col class="col-md-7" col="md-5">
                <template id="StartDate">
                  <div>
                    <label><b>Start Date</b></label>
                    <b-form-datepicker
                      id="start-datepicker"
                      class="mb-2"
                      placeholder="Select Start Date"
                      v-model="ReportParams.param1"
                    ></b-form-datepicker>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-7" col="md-5">
                <template id="EndDate">
                  <div>
                    <label><b>End Date</b></label>
                    <b-form-datepicker
                      id="end-datepicker"
                      class="mb-2"
                      :min="ReportParams.param1"
                      placeholder="Select End Date"
                      v-model="ReportParams.param2"
                    ></b-form-datepicker>
                  </div>
                </template>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>

      <div class="col-md-3">
        <!-- <div class="header-add-red" style="text-align: center;color:#FE2F2F !important;">
                    <a @click="resetFilterItems"><span>Clear Filter</span></a>
                </div> -->
        <div class="mt-16">
          <div class="header-add-Print-Preview" style="text-align: center">
            <div v-if="current_step == '1'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('rptOpenUploadsDeal')"
              >
                <span>Print Preview</span>
              </button>
            </div>
            <div v-if="current_step == '2'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('rptOpenUploadsBroker')"
              >
                <span>Print Preview</span>
              </button>
            </div>
            <div v-if="current_step == '3'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('rptLastUserActivity')"
              >
                <span>Print Preview</span>
              </button>
            </div>
            <div v-else-if="current_step == '4'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('rptUserAccessByDeal')"
              >
                <span>Print Preview</span>
              </button>
            </div>
            <div v-else-if="current_step == '5'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('rptUserActivityLog')"
              >
                <span>Print Preview</span>
              </button>
            </div>
            <div v-else-if="current_step == '6'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('/rptOfferDispo')"
              >
                <span>Print Preview</span>
              </button>
            </div>
            <div v-else-if="current_step == '7'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('/rptOfferHistory')"
              >
                <span>Print Preview</span>
              </button>
            </div>
            <div v-else-if="current_step == '8'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('/rptAdd4')"
              >
                <span>Print Preview</span>
              </button>
            </div>
            <div v-else-if="current_step == '9'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('/rptAdd5')"
              >
                <span>Print Preview</span>
              </button>
            </div>
            <div v-else-if="current_step == '10'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('rptAdd6')"
              >
                <span>Print Preview</span>
              </button>
            </div>
            <div v-else-if="current_step == '11'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('rptAdd7')"
              >
                <span>Print Preview</span>
              </button>
            </div>
            <div v-else-if="current_step == '12'">
              <button
                class="header-add-Print-Preview"
                @click="runReport('rptAdd8')"
              >
                <span>Print Preview</span>
              </button>
            </div>

            <div v-else>
              <span>There are no page found </span>
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
//import DateTime from "./DateTime/DateTime";
//import OutputToExcel from "./CommonComponents/OutputToExcel";
//import Rep from "./CommonComponents/Rep";
import CommonServices from "../service/CommonServices";
// import DBServices from "../service/DBServices";

export default {
  //components: {Rep, DateTime},

  data() {
    return {
      componentURL_user: `Users/?id=${localStorage.getItem("userid")}`,
      componentURL_broker: `Deals/?archived=False&broker_id=${localStorage.getItem(
        "userid"
      )}`,
      componentURL_seller: `Deals/?archived=False&seller_id=${localStorage.getItem(
        "userid"
      )}`,
      componentURL_deal: `Deals/?archived=False&id=${localStorage.getItem(
        "DealID"
      )}`,
      componentURL_buyer: `Deals/?archived=False&buyer_id=${localStorage.getItem(
        "userid"
      )}`,

      renderComponent: true,
      canviewreports: localStorage.getItem("canviewreports"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),

      selected: ["radio-1"],
      checkbox: true,
      SportsFan: true,
      snackbar: false,
      snackColor: "",
      snackText: "",
      picker: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      isShowing: false,
      isShowing_2: false,
      dialog: false,
      manipulatedata: false,
      dialog1: false,
      valid: false,
      current_step: "1",
      export_fields: {},
      activeBtn: 0,
      curUserType: localStorage.getItem("curUserType"),
      currentUser: localStorage.getItem("currentUser"),
      //currentUserType: localStorage.getItem("role"),

      startDate: new Date()
        .toLocaleString()
        .split(/\D/)
        .slice(0, 3)
        .map((num) => num.padStart(2, "0"))
        .join("/"),
      endDate: new Date()
        .toLocaleString()
        .split(/\D/)
        .slice(0, 3)
        .map((num) => num.padStart(2, "0"))
        .join("/"),

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DD"),
        page: "Reports",
        action: "",
        deal_id: "",
        json: "",
      },

      userData: {
        user_id: localStorage.getItem("userid"),
        user_type_id: "",
        full_name: "",
        first_name: "",
        last_name: "",
      },

      ReportParams: {
        user_id: localStorage.getItem("userid"),
        report_id: "",
        report_name: "",
        param1: null,
        param2: 0,
        sort_desc: false,
        user_type_id: localStorage.getItem("curUserType"),
      },

      options: [
        { text: "Asc", value: false },
        { text: "Desc", value: true },
      ],

      list_report: [],
      list_deal: [],
      list_user: [],
      list_broker: [],
      list_buyer: [],
      list_dispo: [],

      startHeaders: [
        { text: "Rep", value: "full_name" },
        { text: "Total", value: "total" },
      ],
      endHeaders: [{ text: "Total", value: "total" }],
    };
  },

  created() {
    this.checkPermissions();
    //this.resetFilterItems();
    this.change_step(localStorage.getItem("current_step"));
    this.logActivity("opened", "Reports");
    console.log("curUserType: ", this.curUserType);

    this.getUserDeals();

    CommonServices.getList("Users/?user_type_id=2")
      .then((res) => {
        console.log("BrokerUsers: ", res.results);
        let codes = res.results;
        codes.map((code) =>
          this.list_broker.push({ id: code.id, text: code.full_name })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("Users")
      .then((res) => {
        console.log("Users: ", res.results);
        let codes = res.results;
        codes.map((code) =>
          this.list_user.push({ id: code.id, text: code.full_name })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList(
      `vwOfferDispo/?seller_id=${localStorage.getItem("userid")}`
    )
      .then((res) => {
        console.log("vwBuyers: ", res.results);
        let codes = res.results;
        codes.map((code) =>
          this.list_buyer.push({ id: code.buyer_id, text: code.buyer })
        );
        console.log("Buyer List: ", this.list_buyer);
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList(this.componentURL_user)
      .then((res) => {
        this.userData = res.results;
        console.log("Current User: ", this.userData[0].full_name);
        localStorage.setItem("curUserType", this.userData[0].user_type_id);
        localStorage.setItem("currentUser", this.userData[0].full_name);
        this.ReportParams = {
          ...this.ReportParams,
          user_type_id: this.userData[0].user_type_id,
        };
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    checkPermissions() {
      //if the user is not an admin then restrict report output to their userid
      if (this.isAdmin == "false");
    },

    logActivity(activity, dealid, page) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.page = page;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    getUserDeals() {
      console.log("Current User: ", this.currentUser);
      console.log("Current User Type: ", this.curUserType);
      if (this.curUserType == 1) {
        this.getAdminDeals();
      }
      if (this.curUserType == 2) {
        this.getBrokerDeals();
      }
      if (this.curUserType == 3) {
        this.getSellerDeals();
      }
      if (this.curUserType == 4) {
        this.getBuyerDeals();
      } else {
        this.getAdminDeals();
      }
    },

    getAdminDeals() {
      CommonServices.getList("Deals/?archived=False")
        .then((res) => {
          console.log("Admin Deals: ", res);
          this.userDeals = res.results;
          this.userDeals.map((code) =>
            this.list_deal.push({ id: code.id, text: code.deal_name })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBrokerDeals() {
      CommonServices.getList(this.componentURL_broker)
        .then((res) => {
          console.log("Broker Deals: ", res);
          this.userDeals = res.results;
          this.userDeals.map((code) =>
            this.list_deal.push({ id: code.id, text: code.deal_name })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBuyerDeals() {
      CommonServices.getList(this.componentURL_buyer)
        .then((res) => {
          console.log("Buyer Deals: ", res);
          this.userDeals = res.results;
          this.userDeals.map((code) =>
            this.list_deal.push({ id: code.id, text: code.deal_name })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSellerDeals() {
      CommonServices.getList(this.componentURL_seller)
        .then((res) => {
          console.log("Seller Deals: ", res);
          this.userDeals = res.results;
          this.userDeals.map((code) =>
            this.list_deal.push({ id: code.id, text: code.deal_name })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    dateSelected() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    runReport(pagename) {
      //Update ReportParams table with the paramters for this report; this will trigger the repopulation of the reports' source table
      //this.getReportParams();
      if (this.ReportParams.param2 == "") this.ReportParams.param2 = 0;
      this.ReportParams.user_id = localStorage.getItem("userid");
      this.ReportParams.report_id = this.current_step;
      this.ReportParams.report_name = pagename;
      //this.setLocalParams();
      console.log("Report Params: ", this.ReportParams);
      CommonServices.postData("ReportParams", this.ReportParams)
        .then((response) => {
          console.log("ReportParams Response: ", response.id);
          CommonServices.sleep(1000);
          this.$router.push({
            path: pagename,
          });
          this.logActivity(
            "Ran Report " + pagename,
            this.ReportParams.param1,
            pagename
          );
          // this.resetFilterItems();
        })
        .catch((error) => {
          console.log("Leads Error: ", error);
        });
    },

    setLocalParams() {
      localStorage.setItem("startdate", this.ReportParams.param1);
      localStorage.setItem("enddate", this.ReportParams.param2);
    },

    postReportParams() {
      CommonServices.putData("ReportParams", this.ReportParams)
        .then((response) => {
          console.log("ReportParams Response: ", response.id);
        })
        .catch((error) => {
          console.log("Put Report Params Error: ", error);
        });
    },

    putReportParams() {
      CommonServices.putData("ReportParams", this.ReportParams)
        .then((response) => {
          console.log("ReportParams Response: ", response.id);
        })
        .catch((error) => {
          console.log("Put Report Params Error: ", error);
        });
    },

    getReportParams() {
      CommonServices.returnSelected("ReportParams", {
        user_id: localStorage.getItem("userid"),
        report_id: this.current_step,
      })
        .then((res) => {
          console.log("returnSelected ReportParams: ", res);
          this.ReportParams = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // setReportParams(){
    //     this.ReportParams.param1=localStorage.getItem('startdate')
    //     this.ReportParams.param2=localStorage.getItem('enddate')
    //     console.log('Current ReportParams: ',this.ReportParams)
    // },

    resetFilterItems() {
      console.log("Resetting filter items...");
      this.ReportParams.param1 = 0;
      this.ReportParams.param2 = 0;
      this.ReportParams.sort_desc = false;
      this.putReportParams();
    },

    closedialog1() {
      this.dialog1 = false;
    },
    change_step(id) {
      this.current_step = id;
      localStorage.setItem("current_step", id);
      //this.setReportParams();
      //this.getReportParams();
    },
    customer() {
      this.$router.push("/CustomerSearch");
    },
    redirect(url) {
      window.location.href = url;
    },
  },
};
</script>

<style type="text/css">
.header-add-Print-Preview button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0091ff;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

.v-item-group.v-bottom-navigation {
  display: block !important;
}

.v-item-group.v-bottom-navigation {
  box-shadow: none !important;
}

.mouse-pointer {
  cursor: pointer;
  margin-left: -22px;
}

.v-item-group.v-bottom-navigation .v-btn {
  /* border-radius: 0; */
  box-shadow: none !important;
  /* flex: 0 1 auto; */
  /* font-size: 0.75rem; */
  height: inherit !important;
  /* max-width: 168px; */
  /* min-width: 80px; */
  /* position: relative; */
  text-transform: none;
}

.v-input
  .v-input--is-label-active
  .v-input--is-dirty
  .theme--light
  .v-input--selection-controls
  .v-input--checkbox
  .primary--text {
  margin-top: -15px !important;
}

.last-two-row-design tbody tr:nth-child(5) td,
.last-two-row-design tbody tr:last-child td {
  font-weight: bold;
}

.tab-wrap-menu {
  height: auto !important;
}
</style>
